import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/web",
    component: () => import("./views/menu"),
    redirect: "/web/dashboard",
    children: [
      {
        path: "dashboard",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/dashboard"),
      },
      {
        path: "me",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/me"),
      },
      {
        path: "notification",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/notification"),
      },

    ]
  },
  {
    path: "/web/voucherList",
    name: 'voucherList',
    props: true,
    beforeEnter: checkAuth,
    component: () => import("./views/voucher/voucherList"),
  },
  {
    path: "/web/product/details",
    name: 'productDetails',
    props: true,
    beforeEnter: checkAuth,
    component: () => import("./views/product/details"),
  },
  {
    path: "/web/hotel/hoteldetails",
    name: 'hotelDetails',
    props: true,
    beforeEnter: checkAuth,
    component: () => import("./views/hotel/detailsHotel"),
  },
  {
    path: "/web/hotel/cartHotel",
    beforeEnter: checkAuth,
    component: () => import("./views/hotel/cartHotel"),
  },
  {
    path: "/web/hotel/checkoutHotel",
    beforeEnter: checkAuth,
    component: () => import("./views/hotel/checkoutHotel"),
  },
  {
    path: "/web/hotel/orderHotelHistory",
    beforeEnter: checkAuth,
    component: () => import("./views/hotel/orderHotelHistory"),
  },
  {
    path: "/web/hotel/orderHotelDetails",
    beforeEnter: checkAuth,
    name: "orderHotelDetials",
    component: () => import("./views/hotel/orderHotelDetails"),
  },
  {
    path: "/web/product/cart",
    beforeEnter: checkAuth,
    component: () => import("./views/product/cart"),
  },
  {
    path: "/web/product/address",
    beforeEnter: checkAuth,
    component: () => import("./views/product/address"),
  },
  {
    path: "/web/product/create-address",
    // name: 'addaddress', props: true,
    beforeEnter: checkAuth,
    component: () => import("./views/product/createAddress.vue"),
  },
  {
    path: "/web/product/addaddress",
    name: 'addaddress', props: true,
    beforeEnter: checkAuth,
    component: () => import("./views/product/addAddress"),
  },
  {
    path: "/web/product/checkout",
    beforeEnter: checkAuth,
    component: () => import("./views/product/checkout"),
  },
  {
    path: "/web/product/orderHistory",
    beforeEnter: checkAuth,
    component: () => import("./views/product/orderHistory"),
  },
  {
    path: "/web/product/orderDetails",
    beforeEnter: checkAuth,
    name: "orderDetials",
    component: () => import("./views/product/orderDetails"),
  },
  {
    path: "/web/product/tracking",
    beforeEnter: checkAuth,
    name: "tracking",
    component: () => import("./views/product/tracking"),
  },
  

  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/login"),
      },
      {
        path: "set_secpassword",
        component: () => import("./views/sessions/set_secpassword"),
      },
      {
        path: "forget_password",
        component: () => import("./views/sessions/forget_password"),
      },
      {
        path: "forget_secpassword",
        component: () => import("./views/sessions/forget_secpassword"),
      },
    ]
  },

  {
    path: "/register",
    component: () => import("./views/sessions/register"),
  },

  {
    path: "/coming_soon",
    component: () => import("./views/pages/coming_soon")
  },

  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.

  if (from.path.includes('manage')) {
    next();

  } else {
    if (to.path) {
      // Start the route progress bar.

      NProgress.start();
      NProgress.set(0.1);
    }
    next();

  }
});

router.afterEach(() => {
  var content = document.getElementById('top');
  if (content) {
    content.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
