


<template>
    <div class="footer_wrap w-100" ref="footer">
        <!-- Footer Start -->
        <div class="app-footer justify-content-center align-items-start mx-3  d-md-flex d-lg-none" v-if="!$route.path.includes('product') && !$route.path.includes('hotel')">
            <b-col cols="3" v-for="item in footer" :key="item.name" @click="item.name === 'home' ? toHome() : toMall(item)"  class="footer-box">
                <div v-if="item.name === 'notification'" class="position-relative">
                    <img v-if="$route.path.includes(item.path)"
                        :src="require('./assets/images/footer/' + item.name + '_a.png')" alt="" height="28px" width="auto">
                    <img v-else 
                        :src="require('./assets/images/footer/' + item.name + '.png')" alt="" height="28px" width="auto">

                    <span v-if="$root.userInfo.unread > 0" class="unread-badge mt-2 mr-4">
                        {{ $root.userInfo.unread }}
                    </span>
                </div>

                <div v-else>
                    <img v-if="$route.path.includes(item.path)"
                        :src="require('./assets/images/footer/' + item.name + '_a.png')" alt="" height="28px" width="auto">
                    <img v-else 
                        :src="require('./assets/images/footer/' + item.name + '.png')" alt="" height="28px" width="auto">
                </div>

                <p class="" :class="{'active': $route.path.includes(item.path), 'text-primary': $route.path.includes(item.path)}">
                    {{ $t(item.name) }}
                </p>
            </b-col>
            <!-- fotter end -->
        </div>
        <div class="footer-background d-none d-lg-block">
            <div class="container">
                <div class="d-flex justify-content-between ">
                    <div class="footer-text1">{{ $t('jabo_copyright') }}</div>
                    <div class="footer-text2 d-flex">
                        <div class="mr-5">{{ $t('tnc') }}</div>
                        <div>{{ $t('privacy_policy') }}</div>
                    </div>
                </div>  
            </div>
        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            footer: [
                { name: 'home' },
                { name: 'mall', path: '/web/dashboard' },
                { name: 'notification', path: '/web/notification' },
                // { name: 'me', path: '/web/product/orderHistory' },
                { name: 'me', path: '/web/me' },
            ]
        };
    },
    methods: {
        toHome() {
            var token = localStorage.getItem('currencyToken');
            var lang = localStorage.getItem('lan');
            
           var baseUrl = `https://${window.location.hostname.replace('mall.', window.location.href.includes('uat')?'.':'')}`
            if (token) {
                var url = `${baseUrl}?key=${encodeURIComponent(token)}&lan=${encodeURIComponent(lang)}`;
                window.location.href = url;
            } else {
                console.error('Token not found in localStorage');
            }
        },
        toMall(item) {
            if (this.$route.path === item.path) {
                this.$router.push({ path: '/refresh', query: { redirect: item.path } }).then(() => {
                    this.$router.push(item.path);
                });
            } else {
                this.$router.push(item.path);
            }
        }
    },
}
</script>
